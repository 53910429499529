.container#loader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  background: rgba(220, 220, 220, 0.8);
  z-index: 4;

  img {
    display: block;
    margin: 0 auto;
    width: 214px;
    height: auto;
    position: absolute;
    bottom: 30vh;
  }
}
