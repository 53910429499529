.hide {
  display: none;
}

#home-lang {
  margin-top: 15px;
}
img {
  height: 7vh;
  vertical-align: middle;
  border-radius: 10px;
}
.img-logo {
  border-radius: 10px;
}
.logoBlue {
  display: inline;
  margin-right: 0.1em;
  font-size: 35px;
  color: #ffffff;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.logoGrey {
  display: inline;
  color: #ffffff;
  font-size: 35px;
}
@font-face {
  font-family: 'quro-ci-font' !important;
  src: url('../fonts/AvenirLTStd-Book.otf') !important;
}

.quro-heading {
  font-family: 'quro-ci-font' !important;
}
.justify {
  justify-content: flex-start !important;
}
.navbar-blue {
  background-color: #223263 !important;
}
.response-content {
  color: #73757d !important;
  margin-top: 85px !important;
  padding-left: 10% !important;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
